<template>

  <b-card-code title="User live data info">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="justify-content-center">
          <b-col
            v-if="this.userData"
            lg="8"
            md="8"
            sm="8"
          >
            <b-card
              align="center"
              bg-variant="transparent"
              border-variant="success"
              class="shadow-none"
              text-variant="green"
              title="Clients' Usage Info"
            >
              <b-card-text>
                <b-list-group v-if="this.userData">
                  <!-- <b-list-group-item v-if="usernameInfo.package_id"
                      ><strong>Package Name : </strong> {{ usernameInfo.package_id }}</b-list-group-item
                    > -->
                  <b-list-group-item
                    v-if="this.userData.mobile"
                  ><strong>PPP Secrets Name : </strong>{{ usernameInfo.user_name }}</b-list-group-item>
                  <b-list-group-item
                    v-if="this.userData.mobile"
                  ><strong>Last App Login : </strong>{{ usernameInfo.last_app_hit }}</b-list-group-item>
                  <b-list-group-item
                    v-if="this.livedata.current_download"
                  ><strong>Current Download :</strong> {{ this.livedata.current_download }}</b-list-group-item>
                  <b-list-group-item
                    v-if="this.livedata.current_upload"
                  ><strong>Current Upload : </strong>{{ this.livedata.current_upload }}</b-list-group-item>
                  <b-list-group-item><strong>Uptime :</strong> {{ this.staticdata.connection_running }}</b-list-group-item>
                  <b-list-group-item><strong>Total Download : </strong>{{ this.staticdata.total_download }}</b-list-group-item>
                  <b-list-group-item><strong>Total Upload : </strong>{{ this.staticdata.total_upload }}</b-list-group-item>
                  <b-list-group-item><strong>Last Connected Time : </strong>{{ this.staticdata.connection_established_at }}</b-list-group-item>
                  <b-list-group-item><strong>Last Disconnected Time : </strong>{{ this.staticdata.last_disconnect_date_time }}</b-list-group-item>
                  <button
                    class="btn btn-primary"
                    style=" margin-right: 1rem; border-color: #00cfe8 !important; background-color: #00cfe8 !important;"
                    @click.prevent="refresh"
                  >
                    Refresh
                  </button>

                </b-list-group>
              </b-card-text>
            </b-card>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormSelect,
  BCardGroup,
  BCard,
  BListGroup,
  BListGroupItem,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'
import {
  ISP_PACKAGE_LIST,
  ISP_ROUTER_LIST,
  USERNAME_VIEW,
  LIVE_DATA,
  STATIC_DATA,
} from '@core/services/api'
import vSelect from 'vue-select'
import router from '@/router'
import Mixin from '@core/services/mixin'
import { checkRes } from '@core/services/helper'
import $ from 'jquery'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormDatepicker,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormSelect,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BCardGroup,
    BCard,
    BListGroup,
    BListGroupItem,
  },
  mixins: [Mixin],
  data() {
    return {
      usernameId: router.currentRoute.params.usernameId,
      userData: '',
      usernameInfo: '',
      livedata: '',
      staticdata: '',
      package_list: [],
      user_list: [],
      router_list: [],
      emailValue: '',
      name: '',
      required,
      invoice_date: null,
      next_due_date: null,
      autogenerate: null,
      selected: [1, 0],
      email,
      status_value: null,
      options_status: [
        { value: '1', text: 'Active' },
        { value: '0', text: 'Inactive' },
      ],
      autogenerateDisable: false,
    }
  },
  mounted() {
    this.isp_id = $('#ispListId option:selected').val()
    this.packageListData(this.isp_id)
    this.routerListData(this.isp_id)
    const instance = this
    $('#ispListId').change(function () {
      this.isp_id = $(this).children('option:selected').val()
      instance.packageListData(this.isp_id)
      instance.routerListData(this.isp_id)
    })
    this.getUserNameData()
    this.getLiveData()
    this.getStaticData()
  },
  methods: {
    changePackage(package_price, packageData) {
      if (package_price) {
        for (const package_price of package_price) {
          if (package_price.value == packageData) {
            this.usernameInfo.package_price = package_price.price
            this.usernameInfo.package_id = package_price.value
          }
        }
      }
    },
    getUserNameData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + USERNAME_VIEW + this.usernameId)
        .then(res => {
          this.usernameInfo = res.data.data
          this.userData = res.data.data.users
          this.autogenerateDisable = this.usernameInfo.autogenerate != 0
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    },
    getLiveData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + LIVE_DATA + this.usernameId)
        .then(res => {
          this.livedata = res.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    },
    getStaticData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + STATIC_DATA + this.usernameId)
        .then(res => {
          this.staticdata = res.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    },
    packageListData(ispId) {
      this.$http
        .get(
          `${process.env.VUE_APP_BASEURL + ISP_PACKAGE_LIST}?isp_id=${ispId}`,
        )
        .then(res => {
          this.package_list = res.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    },
    routerListData(ispId) {
      this.$http
        .get(`${process.env.VUE_APP_BASEURL + ISP_ROUTER_LIST}?isp_id=${ispId}`)
        .then(res => {
          this.router_list = res.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
    },
    refresh() {
      this.getLiveData()
      this.getStaticData()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          useJwt
            .updateCustomerUserName(this.usernameId, {
              user_id: this.usernameInfo.user_id,
              router_id: this.usernameInfo.router_id,
              package_id: this.usernameInfo.package_id,
              package_price: this.usernameInfo.package_price,
              title: this.usernameInfo.title,
              user_name: this.usernameInfo.user_name,
              autogenerate: this.usernameInfo.autogenerate,
              status: this.usernameInfo.status_value,
              do_not_update_meta: true,
            })
            .then(response => {
              if (checkRes(response.data.code)) {
                this.autogenerateDisable = response.data.data.autogenerate != 0
                this.$router
                  .push({ name: 'isp-user-name-edit' })
                  .catch(error => {
                    this.toastMessage('success', 'Assign Dail Up', response)
                  })
              } else {
                this.$router
                  .push({ name: 'isp-user-name-edit' })
                  .catch(error => {
                    this.toastMessage('warning', 'Assign Dail Up', response)
                  })
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    // Make New Invoice
    createNewInvoice() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          useJwt
            .createNewInvoice(this.usernameId, {
              isp_id: this.isp_id ?? null,
              user_id: this.usernameInfo.user_id,
              grand_total: this.usernameInfo.package_price,
              next_invoice_date: this.usernameInfo.invoice_date,
              next_due_date: this.usernameInfo.next_due_date,
            })
            .then(response => {
              console.log(response)
              if (checkRes(response.data.code)) {
                this.$router
                  .push({ name: 'isp-user-name-edit' })
                  .catch(error => {
                    this.toastMessage(
                      'success',
                      'Invoice Create Successfully',
                      response,
                    )
                  })
              } else {
                this.$router
                  .push({ name: 'isp-user-name-edit' })
                  .catch(error => {
                    this.toastMessage('warning', 'Invoice', response)
                  })
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    // comfirm button color
    confirmDeleteUserNames() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt
            .deleteCustomerUserName({
              user_id: this.usernameId,
            })
            .then(response => {
              if (checkRes(response.data.code)) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.$router
                  .push({ name: 'isp-username-list' })
                  .catch(error => {
                    this.toastMessage(
                      'success',
                      'Delete Successfully',
                      response,
                    )
                  })
              } else {
                this.$swal({
                  title: 'Cancelled',
                  text: 'Your imaginary file is safe :)',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => {
              this.$swal({
                title: 'Cancelled',
                text: 'Your imaginary file is safe :)',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Your imaginary file is safe :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>
